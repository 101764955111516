//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Service extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Target } = this.props
        //Element
        let A16S8G = " "
        //Element
        let K7X360 = "."
        //Element
        let PX130W = "Web_Services_" + ID
        //Element
        const Content = React.createElement( "a", { id: PX130W, className: "float-start w-100 p-0 m-0 fu22h2", title: Title, href: Target },
            //Element
            React.createElement( "div", { className: "float-start w-50 p-0 pt-5 pb-5 pe-5 m-0 c3ev52" },
                //Element
                React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-center align-items-start p-0 m-0 i58nk0" },
                    //Element
                    React.createElement( "h4", { className: "float-start w-auto p-0 m-0 n8037e" },
                        //Element
                        ID + K7X360 + A16S8G + Title
                    ),
                //Element
                React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 y01k42" },
                    //Element
                    "Developing a unique brand identity, including logo design, brand guidelines, and visual assets."
                ),
                //Element
                React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 y01k42" },
                    //Element
                    React.createElement( "strong", {},
                        //Element
                        "Brand Strategy : "
                    ),
                    //Element
                    "Developing a comprehensive brand strategy that aligns with the business goals and target audience."
                ),
                //Element
                React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 y01k42" },
                    //Element
                    React.createElement( "strong", {},
                        //Element
                        "Logo Design : "
                    ),
                    //Element
                    "Creating a unique and memorable logo that represents the brand's values and identity"
                )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-50 p-0 ps-5 pe-5 pt-5 pb-5 m-0 qk056a" },
                //Element
                React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-center align-items-start p-0 m-0 i58nk0" },
                    //Element
                    React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 j974ze" },
                        //Element
                        React.createElement( "strong", {},
                            //Element
                            "Visual Identity : "
                        ),
                        //Element
                        "Designing consistent visual elements such as color palette, typography, and imagery to create a cohesive brand image."
                    ),
                    //Element
                    React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 j974ze" },
                        //Element
                        React.createElement( "strong", {},
                            //Element
                            "Brand Messaging : "
                        ),
                        //Element
                        "Crafting compelling and consistent messaging that communicates the brand's story, values, and unique selling propositions."
                    ),
                    //Element
                    React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 j974ze" },
                        //Element
                        React.createElement( "strong", {},
                            //Element
                            "Brand Guidelines : "
                        ),
                        //Element
                        "Developing brand guidelines that outline the proper usage of the brand's visual elements and messaging across various platforms and channels."
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Service.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Service;