//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Services extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Target } = this.props
        //Element
        let A16S8G = " "
        //Element
        let K7X360 = "."
        //Element
        let PX130W = "Web_Services_" + ID
        //Element
        const Content = React.createElement( "a", { id: PX130W, className: "float-start w-50 p-0 m-0 fu22h2", title: Title, href: Target },
            //Element
            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 ps-5 pe-5 m-0 y931x3" },
                //Element
                React.createElement( "h4", { className: "float-start w-auto p-0 m-0 q1779z" },
                    //Element
                    ID + K7X360 + A16S8G + Title
                ),
                //Element
                React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 tkv048" },
                    //Element
                    Summary
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Services.propTypes = {
    ID      : propTypes.string.isRequired,
    Title   : propTypes.string.isRequired,
    Summary : propTypes.string.isRequired,
    Target  : propTypes.string.isRequired
}
//Element
export default Services;