//Install
import React, { Component } from "react";
//Install
import { ReactComponent as Z028OO } from "../../Media/Icon/002.svg";
//Install
import { ReactComponent as G899P8 } from "../../Media/Icon/003.svg";
//Install
import T4040I from "../../Media/Image/004.png";

//Element
class Default extends Component {
    //Element
    X9R053 = (e) => {
        //Element
        e.preventDefault()
        //Element
        let L4S6J4 = document.getElementsByTagName( "body" )[0]
        //Element
        let WQ8709 = document.getElementById( "Web_H16J1Y" )
        //Element
        let II079C = document.getElementById( "Web_E8478B" )
        //Element
        let X2A635 = document.getElementsByClassName( "h3l5b9" )[0]
        //Element
        WQ8709.classList.toggle( "d-none" )
        //Element
        II079C.classList.toggle( "d-none" )
        //Element
        L4S6J4.classList.toggle( "overflow-hidden" )
        //Element
        X2A635.classList.toggle( "d-none" )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-between align-items-center p-0 m-0 b7376e" },
            //Element
            React.createElement( "a", { className: "float-start w-auto p-0 m-0 u661kl", title: "Cover Studio", href: "/" },
                //Element
                React.createElement( "img", { alt: "Cover Studio", title: "Cover Studio", src: T4040I, width: "200", height: "auto" } )
            ),
            //Element
            React.createElement( "button", { id: "Web_H16J1Y", name: "Web_H16J1Y", className: "float-start w-auto p-0 m-0 bg-transparent border-0 t736g7", type: "button", onClick: this.X9R053 },
                //Element
                React.createElement( Z028OO, {} )
            ),
            //Element
            React.createElement( "button", { id: "Web_E8478B", name: "Web_E8478B", className: "float-start w-auto p-0 m-0 bg-transparent border-0 d-none y4r83a", type: "button", onClick: this.X9R053 },
                //Element
                React.createElement( G899P8, {} )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;