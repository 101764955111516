//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Button extends Component {
    //Element
    T9505P = (e) => {
        //Element
        const { ID } = this.props
        //Element
        e.preventDefault()
        //Element
        window.location.hash = "Web_Services_" + ID
    }
    //Element
    render() {
        //Element
        const { ID, Title, Target } = this.props
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 q3412g" },
            //Element
            React.createElement( "button", { className: "float-start w-100 p-0 m-0 mt-4 rounded-circle m4q81o", type: "button", onClick: this.T9505P },
                //Element
                React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 rounded-circle b4e26g" },
                    //Element
                    ID
                )
            ),
            //Element
            React.createElement( "span", { className: "float-start w-100 p-0 m-0 mt-3 text-center kv554t" },
                //Element
                Title
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Button.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Button;