//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Blog extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Summary, Category, Image, Target } = this.props
        //Element
        let Source = "https://vault.uicore.co/creative-agency/wp-content/uploads/sites/30/2022/07/"
        //Element
        let Media  = Source + Image
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-0 m-0 b1935l", title: Title, href: Target },
            //Element
            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 mb-2 i189aw" },
                //Element
                React.createElement( "img", { alt: Title, title: Title, src: Media, width: "416", height: "335" } )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-3 x8o716" },
                //Element
                React.createElement( "span", { className: "float-start w-auto position-relative s727i8" },
                    //Element
                    Category
                ),
                //Element
                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-3 b46n42" },
                    //Element
                    Title
                ),
                //Element
                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 zbh982" },
                    //Element
                    Summary
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Blog.propTypes = {
    ID       : propTypes.string.isRequired,
    Title    : propTypes.string.isRequired,
    Summary  : propTypes.string.isRequired,
    Category : propTypes.string.isRequired,
    Image    : propTypes.string.isRequired,
    Target   : propTypes.string.isRequired
}
//Element
export default Blog;