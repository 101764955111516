//Install
import React, { Component } from "react";

//Element
class SET extends Component {
    //Element
    C25N33 = (e) => {
        //Element
        e.preventDefault()
        //Element
        window.location.href = "/contact"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 justify-content-end align-items-center p-0 m-0 mq4603" },
            //Element
            React.createElement( "button", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 tc91k2", type: "button", onClick: this.C25N33 },
                //Element
                "Contact"
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default SET;