//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import SET from "./Header/SET";
//Install
import Menu from "./Header/Menu";
//Install
import Brand from "./Header/Brand";
//Install
import Mobile from "./Mobile/Default";
//Install
import Submenu from "./Mobile/Menu";

//Element
class Header extends Component {
    //Element
    AD784T = () => {
        //Element
        let X213GP = document.getElementsByClassName( "mq4603" )[0]
        //Element
        let A061J5 = X213GP.offsetTop
        //Element
        if( window.pageYOffset > A061J5 ){
            //Element
            X213GP.classList.add( "position-fixed" )
            //Element
            X213GP.classList.add( "start-0" )
            //Element
            X213GP.classList.add( "top" )
        //Element
        } else {
            //Element
            X213GP.classList.remove( "position-fixed" )
            //Element
            X213GP.classList.remove( "start-0" )
            //Element
            X213GP.classList.remove( "top" )
        }
    }
    //Element
    componentDidMount(){
        //Element
        window.addEventListener( "scroll", this.AD784T )
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 k90k22" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 d-none v972b4" } ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mq4603" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( Brand, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:8 },
                            //Element
                            React.createElement( Menu, {} )
                        ),
                        //Element
                        React.createElement( Col, { md:2 },
                            //Element
                            React.createElement( SET, {} )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 uvx453" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Mobile, {} )
                        ),
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-absolute d-none h3l5b9" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Submenu, {} )
                        )
                    )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Header;