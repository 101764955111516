//Install
import React, { Component } from "react";
//Install
import { ReactComponent as H9W426 } from "../../Media/Social/002.svg";
//Install
import { ReactComponent as VU176H } from "../../Media/Social/003.svg";
//Install
import { ReactComponent as A60D99 } from "../../Media/Social/004.svg";
//Install
import { ReactComponent as W66XN2 } from "../../Media/Social/005.svg";
//Install
import { ReactComponent as Z262D6 } from "../../Media/Social/006.svg";
//Install
import propTypes from "prop-types";

//Element
class Social extends Component {
    //Element
    render() {
        //Element
        const { Facebook, X, Instagram, Youtube, Linkedin } = this.props
        //Element
        const Content = React.createElement( "ul", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 fb596t" },
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto p-0 m-0 me-2", title: "Facebook", href: Facebook },
                    //Element
                    React.createElement( H9W426, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto p-0 m-0 ms-4 me-4", title: "X", href: X },
                    //Element
                    React.createElement( VU176H, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto p-0 m-0 ms-2 me-4", title: "Instagram", href: Instagram },
                    //Element
                    React.createElement( A60D99, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto p-0 m-0 ms-2 me-4", title: "Youtube", href: Youtube },
                    //Element
                    React.createElement( W66XN2, {} )
                )
            ),
            //Element
            React.createElement( "li", { className: "float-start w-auto" },
                //Element
                React.createElement( "a", { className: "float-start w-auto p-0 m-0 ms-2", title: "Linkedin", href: Linkedin },
                    //Element
                    React.createElement( Z262D6, {} )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Social.propTypes = {
    ID        : propTypes.string.isRequired,
    Facebook  : propTypes.string.isRequired,
    X         : propTypes.string.isRequired,
    Instagram : propTypes.string.isRequired,
    Youtube   : propTypes.string.isRequired,
    Linkedin  : propTypes.string.isRequired
}
//Element
export default Social;