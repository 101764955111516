//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";
//Install
import Service from "../../Content/Service";
//Install
import Services from "../../Content/Services";
//Install
import { ReactComponent as B463CN } from "../../../Media/Icon/004.svg";
//Install
import { ReactComponent as Y76S2K } from "../../../Media/Icon/005.svg";

//Element
class Default extends Component {
    //Element
    EI13R9(){
        //Element
        let OM175Z     = document.querySelector( "meta[property='og:title']" )
        //Element
        let T00A3U     = document.querySelector( "meta[property='og:description']" )
        //Element
        let HU221M     = document.querySelector( "meta[property='og:image']" )
        //Element
        let V337GE     = document.querySelector( "meta[property='og:url']" )
        //Element
        OM175Z.content = "Services | Cover-Studio"
        //Element
        T00A3U.content = "Showcase your work at the cover of the market."
        //Element
        HU221M.content = "https://cover-studio.com/favicon.svg"
        //Element
        V337GE.content = "https://cover-studio.com/services"
    }
    //Element
    Q8283L(){
        //Element
        let N09HE5     = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let D68001     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let N94MJ9     = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let S577FO     = document.querySelector( "meta[name='twitter:url']" )
        //Element
        N09HE5.content = "Services | Cover-Studio"
        //Element
        D68001.content = "Showcase your work at the cover of the market."
        //Element
        N94MJ9.content = "https://cover-studio.com/favicon.svg"
        //Element
        S577FO.content = "https://cover-studio.com/services"
    }
    //Element
    N2782S(){
        //Element
        let AHF319     = document.querySelector( "meta[itemprop='name'" )
        //Element
        let W2LB44     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let K08577     = document.querySelector( "meta[itemprop='image']" )
        //Element
        AHF319.content = "Services | Cover-Studio"
        //Element
        W2LB44.content = "Showcase your work at the cover of the market."
        //Element
        K08577.content = "https://cover-studio.com/favicon.svg"
    }
    //Element
    componentDidMount(){
        //Element
        document.title                                                                    = "Services | Cover-Studio"
        //Element
        document.getElementsByTagName( "meta" )["description"].content                    = "Showcase your work at the cover of the market."
        //Element
        document.querySelectorAll( "link[rel='canonical']" )[0].setAttribute( "href", "https://cover-studio.com/services" )
        //Element
        this.EI13R9()
        //Element
        this.Q8283L()
        //Element
        this.N2782S()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 s288ei" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 o747gy" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative s40258" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:12 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 uf9065" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-auto p-0 m-0 c137ai" },
                                        //Element
                                        "‘Showcase your work at the cover of the market’"
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 mb-5 x195er" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:12 },
                            //Element
                            React.createElement( Service, { ID: "1", Title: "Branding and Identity", Summary: "", Target: "/" } ),
                            //Element
                            React.createElement( Services, { ID: "2", Title: "Graphic Design", Summary: "Creating visually appealing designs for various purposes, such as brochures, posters, packaging, business cards, invitations, and digital graphics.", Target: "/" } ),
                            //Element
                            React.createElement( Services, { ID: "3", Title: "Web Design and Development", Summary: "Designing and building websites that are visually appealing, user-friendly, and optimized for search engines.", Target: "/" } ),
                            //Element
                            React.createElement( Services, { ID: "4", Title: "Social Media Management", Summary: "Developing and implementing social media strategies, creating engaging content, and managing social media accounts.", Target: "/" } ),
                            //Element
                            React.createElement( Services, { ID: "5", Title: "Content Creation", Summary: "Producing high-quality content for various platforms, including blog posts, articles, videos, and infographics.", Target: "/" } ),
                            //Element
                            React.createElement( Services, { ID: "6", Title: "Advertising and Marketing", Summary: "Planning and executing creative advertising and marketing campaign across different channels ; print, digital, and social media.", Target: "/" } ),
                            //Element
                            React.createElement( Services, { ID: "7", Title: "Photography and Videography", Summary: "Capturing professional photos and videos for promotional purposes, such as product photography, corporate videos, and event coverage.", Target: "/" } ),
                            //Element
                            React.createElement( Services, { ID: "8", Title: "Copywriting", Summary: "Crafting compelling and persuasive copy for websites, advertisements, and other marketing materials.", Target: "/" } ),
                            //Element
                            React.createElement( Services, { ID: "9", Title: "SEO and Digital Marketing", Summary: "Optimizing websites for search engines, conducting keyword research, and implementing digital marketing strategies to increase online visibility and drive traffic.", Target: "/" } ),
                            //Element
                            React.createElement( Services, { ID: "10", Title: "Event Planning and Management", Summary: "Organizing and managing corporate events, product launches, and promotional activities.", Target: "/" } ),
                            //Element
                            React.createElement( Services, { ID: "11", Title: "Brand Analysis", Summary: "Monitoring the brand's reputation and performance, analyzing market trends, and making data-driven decisions to continuously improve the brand strategy.", Target: "/" } )
                        )
                    )
                )
            ),
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;