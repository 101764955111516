//Install
import React, { Component } from "react";
//Install
import propTypes from "prop-types";

//Element
class Brand extends Component {
    //Element
    render() {
        //Element
        const { ID, Title, Image, Target } = this.props
        //Element
        let Source = "https://priveepr.com/assets/theme-file/img/logos3/"
        //Element
        let Media  = Source + Image
        //Element
        const Content = React.createElement( "a", { className: "float-start w-100 p-0 ps-5 pe-5 m-0 m37p4r", title: "", href: "/" },
            //Element
            React.createElement( "picture", { className: "float-start w-100 p-0 m-0 ue641t" },
                //Element
                React.createElement( "img", { alt: Title, title: Title, src: Media, width: "164", height: "85" } )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
Brand.propTypes = {
    ID     : propTypes.string.isRequired,
    Title  : propTypes.string.isRequired,
    Image  : propTypes.string.isRequired,
    Target : propTypes.string.isRequired
}
//Element
export default Brand;