//Install
import React, { Component } from "react";

//Element
class About extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-center align-items-start p-0 ps-5 m-0 xx3i47" },
            //Element
            React.createElement( "h1", { className: "float-start w-auto p-0 m-0 lh-1 s69j76" },
                //Element
                "Unleashing Creativity"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 v7d78o" },
                //Element
                "“Inside the World of a Cool Luxury Design Studio”"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 oau798" },
                //Element
                "In today's fast-paced and visually-driven world, the role of design studios has become more crucial than ever. These creative hubs are not only responsible for shaping luxury brands and products but also for setting trends and pushing boundaries."
            ),
            //Element
            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 oau798" },
                //Element
                "Cover Studio team; explores unique approach to creativity, through innovative projects, and international know-how. ",
                //Element
                React.createElement( "strong", {},
                    //Element
                    "Pushing the boundaries of design in the luxury world, "
                ),
                //Element
                "experimenting with new techniques, materials, and technologies."
            ),
            //Element
            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 oau798" },
                //Element
                React.createElement( "strong", {},
                    //Element
                    "Creating memorable experiences for our clients. Cover Studio "
                ),
                //Element
                "go beyond aesthetics and focus on crafting experiences that evoke emotions and leave a lasting impact. From immersive installations to interactive digital interfaces, our designs engage and captivate audiences, making them stand out in a crowded marketplace."
            ),
            //Element
            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 d-none oau798" },
                //Element
                React.createElement( "strong", {},
                    //Element
                    "Practical and user-friendly. "
                ),
                //Element
                "By combining their artistic vision with a deep understanding of user needs, ",
                //Element
                React.createElement( "strong", {},
                    //Element
                    "Cover Studio "
                ),
                //Element
                "create solutions that are both visually stunning and highly functional."
            ),
            //Element
            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 d-none oau798" },
                //Element
                React.createElement( "strong", {},
                    //Element
                    "Staying ahead of trends : Cover Studio "
                ),
                //Element
                "keep a finger on the pulse of emerging design movements, consumer preferences, and technological advancements."
            ),
            //Element
            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-3 d-none oau798" },
                //Element
                "By staying informed and continuously learning, they are able to anticipate future demands and deliver cutting-edge designs that resonate with their target audience."
            ),
            //Element
            React.createElement( "a", { className: "float-start w-100 p-0 m-0 mt-3 text-center e114dj", href: "/about-us" },
                //Element
                "Learn more"
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default About;