//Install
import React, { Component } from "react";
//Install
import { Container, Row, Col } from "react-bootstrap";
//Install
import { Swiper, SwiperSlide } from "swiper/react";
//Install
import { Navigation, Pagination, Autoplay } from "swiper/modules";

//Element
class Default extends Component {
    //Element
    EI13R9(){
        //Element
        let OM175Z     = document.querySelector( "meta[property='og:title']" )
        //Element
        let T00A3U     = document.querySelector( "meta[property='og:description']" )
        //Element
        let HU221M     = document.querySelector( "meta[property='og:image']" )
        //Element
        let V337GE     = document.querySelector( "meta[property='og:url']" )
        //Element
        OM175Z.content = "Contact | Cover-Studio"
        //Element
        T00A3U.content = "Showcase your work at the cover of the market."
        //Element
        HU221M.content = "https://cover-studio.com/favicon.svg"
        //Element
        V337GE.content = "https://cover-studio.com/contact"
    }
    //Element
    Q8283L(){
        //Element
        let N09HE5     = document.querySelector( "meta[name='twitter:title']" )
        //Element
        let D68001     = document.querySelector( "meta[name='twitter:description']" )
        //Element
        let N94MJ9     = document.querySelector( "meta[name='twitter:image']" )
        //Element
        let S577FO     = document.querySelector( "meta[name='twitter:url']" )
        //Element
        N09HE5.content = "Contact | Cover-Studio"
        //Element
        D68001.content = "Showcase your work at the cover of the market."
        //Element
        N94MJ9.content = "https://cover-studio.com/favicon.svg"
        //Element
        S577FO.content = "https://cover-studio.com/contact"
    }
    //Element
    N2782S(){
        //Element
        let AHF319     = document.querySelector( "meta[itemprop='name'" )
        //Element
        let W2LB44     = document.querySelector( "meta[itemprop='description']" )
        //Element
        let K08577     = document.querySelector( "meta[itemprop='image']" )
        //Element
        AHF319.content = "Contact | Cover-Studio"
        //Element
        W2LB44.content = "Showcase your work at the cover of the market."
        //Element
        K08577.content = "https://cover-studio.com/favicon.svg"
    }
    //Element
    componentDidMount(){
        //Element
        document.title                                                                    = "Contact | Cover-Studio"
        //Element
        document.getElementsByTagName( "meta" )["description"].content                    = "Showcase your work at the cover of the market."
        //Element
        document.querySelectorAll( "link[rel='canonical']" )[0].setAttribute( "href", "https://cover-studio.com/contact" )
        //Element
        this.EI13R9()
        //Element
        this.Q8283L()
        //Element
        this.N2782S()
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 s288ei" },
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 o747gy" },
                //Element
                React.createElement( "div", { className: "float-start w-100 p-0 m-0 position-relative s40258" },
                    //Element
                    React.createElement( Container, {},
                        //Element
                        React.createElement( Row, {},
                            //Element
                            React.createElement( Col, { md:12 },
                                //Element
                                React.createElement( "div", { className: "d-flex w-100 justify-content-center align-items-center p-0 m-0 uf9065" },
                                    //Element
                                    React.createElement( "h1", { className: "float-start w-auto p-0 m-0 c137ai" },
                                        //Element
                                        "‘Showcase your work at the cover of the market’"
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 j1w74y" },
                //Element
                React.createElement( Container, {},
                    //Element
                    React.createElement( Row, {},
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "float-start w-100 p-5 m-0 mt-4 mb-4 v72x3w" },
                                //Element
                                React.createElement( "form", { className: "float-start w-100 p-0 m-0 zy525o", method: "GET" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:6 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 q818tu" },
                                                //Element
                                                React.createElement( "input", { id: "Web_", name: "Web_", className: "float-start w-100 p-0 ps-4 pe-4 m-0 mt-4 bg-white border-0 ad757r", placeholder: "Your name", type: "text", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:6 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 q818tu" },
                                                //Element
                                                React.createElement( "input", { id: "Web_", name: "Web_", className: "float-start w-100 p-0 ps-4 pe-4 m-0 mt-4 bg-white border-0 ad757r", placeholder: "E-mail Address", type: "email", required: "required" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:12 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-0 q818tu" },
                                                //Element
                                                React.createElement( "textarea", { id: "Web_", name: "Web_", className: "float-start w-100 p-4 m-0 mt-4 bg-white border-0 i556so", placeholder: "Subject", required: "required", rows: "5", cols: "20" } )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:12 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mb-4 q818tu" },
                                                //Element
                                                React.createElement( "button", { id: "Web_", name: "Web_", className: "float-start w-100 p-0 m-0 mt-4 g17x42", type: "button" },
                                                    //Element
                                                    "Submit"
                                                )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:12 },
                                            //Element
                                            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-0 q818tu" },
                                                //Element
                                                React.createElement( "label", { className: "float-start w-100 p-0 m-0 mt-3 text-center d-none k70q02" },
                                                    //Element
                                                    "Thank You"
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        ),
                        //Element
                        React.createElement( Col, { md:6 },
                            //Element
                            React.createElement( "div", { className: "d-flex w-100 h-100 flex-column justify-content-center align-items-center p-0 m-0 mt-4 mb-4 p563v7" },
                                //Element
                                React.createElement( "h3", { className: "float-start w-100 p-0 m-0 a56651" },
                                    //Element
                                    "Get In Touch"
                                ),
                                //Element
                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-2 j99di5" },
                                    //Element
                                    "We are here to answer any question you may have. Feel free to reach via contact form."
                                ),
                                //Element
                                React.createElement( "div", { className: "float-start w-100 p-0 m-0 j9s090" },
                                    //Element
                                    React.createElement( Row, {},
                                        //Element
                                        React.createElement( Col, { md:12 },
                                            //Element
                                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-start align-items-start p-0 m-0 mt-4 s1z891" },
                                                //Element
                                                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 a3343b" },
                                                    //Element
                                                    "E-mail Address"
                                                ),
                                                //Element
                                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 j99di5" },
                                                    //Element
                                                    "00 90 (212) 249 05 27 - 00 90 (212) 249 05 22"
                                                )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:6 },
                                            //Element
                                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-start align-items-start p-0 m-0 mt-4 s1z891" },
                                                //Element
                                                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 a3343b" },
                                                    //Element
                                                    "Company Telephone"
                                                ),
                                                //Element
                                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 j99di5" },
                                                    //Element
                                                    "info@cover-studio.com"
                                                )
                                            )
                                        ),
                                        //Element
                                        React.createElement( Col, { md:12 },
                                            //Element
                                            React.createElement( "div", { className: "d-flex w-100 flex-column justify-content-start align-items-start p-0 m-0 mt-4 s1z891" },
                                                //Element
                                                React.createElement( "h6", { className: "float-start w-100 p-0 m-0 a3343b" },
                                                    //Element
                                                    "Address"
                                                ),
                                                //Element
                                                React.createElement( "p", { className: "float-start w-100 p-0 m-0 mt-1 j99di5" },
                                                    //Element
                                                    "Körkadı Sokak, Villa 22 / 2 Ulus / İstanbul - Türkiye"
                                                )
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            ),
            //Element
            React.createElement( "div", { className: "float-start w-100 p-0 m-0 mt-4 j1w74y" },
                //Element
                React.createElement( "iframe", { width: "600", height: "450", src: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d6015.868668357615!2d29.032484!3d41.070428!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab9d8b72e0dd5%3A0xe3a96db25991c6aa!2sPriv%C3%A9e%20PR%20Istanbul!5e0!3m2!1str!2sus!4v1726567413194!5m2!1str!2sus", className: "float-start w-100 border-0" })
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Default;