//Install
import React, { Component } from "react";
//Install
import { ReactComponent as S1JH27 } from "../../Media/Icon/001.svg";

//Element
class Newsletter extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "float-start w-100 p-0 m-0 y131cy" },
            //Element
            React.createElement( "h6", { className: "float-start w-100 p-0 m-0 mt-4 a93n9n" },
                //Element
                "Bulletin Subscription"
            ),
            //Element
            React.createElement( "form", { className: "float-start w-100 p-0 m-0 k09j65" },
                //Element
                React.createElement( "input", { id: "Web_Z5AK93", name: "Web_Z5AK93", className: "float-start w-100 p-0 m-0 ke0707", placeholder: "E-mail", type: "email", required: "required" } ),
                //Element
                React.createElement( "button", { id: "Web_ZC918D", name: "Web_ZC918D", className: "d-flex w-auto justify-content-start align-items-center p-0 m-0 bg-transparent border-0 position-absolute end-0 top-0 w3626o", type: "button" },
                    //Element
                    React.createElement( S1JH27, {} )
                )
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Newsletter;