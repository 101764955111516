//Install
import React, { Component } from "react";

//Element
class AIM extends Component {
    //Element
    render() {
        //Element
        const Content = React.createElement( "div", { className: "d-flex flex-column justify-content-start align-items-start p-0 m-0 mt-4 u2k9z3" },
            //Element
            React.createElement( "h2", { className: "float-start w-auto p-0 m-0 lh-1 x11q7x" },
                //Element
                "AIM"
            ),
            //Element
            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 h8697j" },
                //Element
                React.createElement( "strong", {},
                    //Element
                    "Pushing the boundaries of design in the luxury world, "
                ),
                //Element
                "experimenting with new techniques, materials, and technologies."
            ),
            //Element
            React.createElement( "hr", {} ),
            //Element
            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 h8697j" },
                //Element
                React.createElement( "strong", {},
                    //Element
                    "Creating memorable experiences for our clients. Cover Studio "
                ),
                //Element
                "go beyond aesthetics and focus on crafting experiences that evoke emotions and leave a lasting impact. From immersive installations to interactive digital interfaces, our designs engage and captivate audiences, making them stand out in a crowded marketplace."
            ),
            //Element
            React.createElement( "hr", {} ),
            //Element
            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 h8697j" },
                //Element
                React.createElement( "strong", {},
                    //Element
                    "Practical and user-friendly. "
                ),
                //Element
                "By combining their artistic vision with a deep understanding of user needs, ",
                //Element
                React.createElement( "strong", {},
                    //Element
                    "Cover Studio "
                ),
                //Element
                "create solutions that are both visually stunning and highly functional."
            ),
            //Element
            React.createElement( "hr", {} ),
            //Element
            React.createElement( "p", { className: "float-start w-auto p-0 m-0 mt-2 h8697j" },
                //Element
                React.createElement( "strong", {},
                    //Element
                    "Staying ahead of trends : Cover Studio "
                ),
                //Element
                "keep a finger on the pulse of emerging design movements, consumer preferences, and technological advancements. By staying informed and continuously learning, they are able to anticipate future demands and deliver cutting-edge designs that resonate with their target audience."
            )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default AIM;