//Install
import React, { Component } from "react";
//Install
import U1907G from "../../Media/Image/004.png";

//Element
class Brand extends Component {
    //Element
    U7O159 = () => {
        //Element
        window.location.href = "/"
    }
    //Element
    render() {
        //Element
        const Content = React.createElement( "button", { className: "d-flex w-100 justify-content-start align-items-center p-0 m-0 bg-transparent border-0 u0v354", onClick: this.U7O159 },
            //Element
            React.createElement( "img", { alt: "Cover-Studio", title: "Cover-Studio", src: U1907G, width: "", height: "" } )
        )
        //Element
        return (
            Content
        );
    }
}
//Element
export default Brand;